import React from 'react'

const Loading = () => (
	<div className="app-overlay-loader">
        <div className="d-flex flex-grow-1 justify-content-center align-items-center h-100">
    		<div className="row">
    			<div className="col-12 my-5 py-5">
    				<div className="spinner-grow" role="status">
    					<span className="sr-only">Loading...</span>
    				</div>
    			</div>
    		</div>
        </div>
        <style>{`
            .app-overlay-loader {
                position: absolute;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background: rgba(0, 0, 0, 0.5);
                z-index: 1040;
                display: none;
            }

            .spinner-grow {
                color: #572148 !important;
            }
        `}</style>
	</div>
)

export default Loading;
