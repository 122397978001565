import axios from 'axios';

let jwtToken = localStorage.getItem('jwtToken');

export const setJwtToken = (token) => jwtToken = token;

axios.interceptors.request.use((config) => {
    const token = jwtToken;
    if(!config.headers['authorization'] && !config.headers['Authorization'])
        config.headers['Authorization'] = `${token}`;
    if(document.getElementsByClassName("app-overlay-loader")[0] !== undefined) document.getElementsByClassName("app-overlay-loader")[0].style.display = "block";
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    if(document.getElementsByClassName("app-overlay-loader")[0] !== undefined) document.getElementsByClassName("app-overlay-loader")[0].style.display = "none";
    if(response.data.statusCode >= 400 || response.data.errorMessage) {
		let error = {
			message : 'Something went wrong!'
		}
		if(response.data.statusCode === 500) error.message = 'Something went wrong!';
		else {
			if(response.data.errorMessage) error.message = response.data.errorMessage;
			if(response.data.error) error.message = response.data.error;
			if(response.data.message) error.message = response.data.message;
            if(response.data.message.message) error.message = response.data.message.message;
		}
		throw error;
	} else {
		if(response.data.error) throw new Error(response.data.error);
		if(response.data.message) response.data = response.data.message;
	}
	return response;
}, (error) => {
    if(document.getElementsByClassName("app-overlay-loader")[0] !== undefined) document.getElementsByClassName("app-overlay-loader")[0].style.display = "none";
    if(error.response) {
        //if(error.response.status === 404) window.location.href = "/error/error-404";
        //if(error.response.status === 401 && (error.response.data === 'Not Authorised')) window.location.href = "/error/error-404";
        //if(error.response.status === 401) window.location.href = "/logout";
        if(error.response.status === 500) error.message =  'Something went wrong!';
		//else {
			error.message = error.response.data
		//}
    }
    return Promise.reject(error);
});

export default axios;
