import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import Amplify, { Auth } from "aws-amplify";
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import Cookies from 'js-cookie';

import OverlayLoader from './reusable/OverlayLoader';
import AuthContext from "./contexts/AuthContext";
import awsconfig from "./config/aws.exports";

import "@coreui/coreui/scss/coreui.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/style.scss';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-toastify/dist/ReactToastify.css';

import { setJwtToken } from "./crud/axios";

Amplify.configure(awsconfig);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./components/Auth/Login'));
const Signup = React.lazy(() => import('./components/Auth/Signup'));
const ForgotPassword = React.lazy(() => import('./components/Auth/ForgotPassword'));
const NewPassword = React.lazy(() => import('./components/Auth/NewPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {
    constructor(props) {
		super(props);
		this.state = {
			user: { attributes: {} },
			signedIn: null,
            privileges: {},
            refreshToken: null
		}
        onAuthUIStateChange(newAuthState  => {
      		this.checkSession()
    	})
	}

    componentDidMount() {
		this.checkSession();
	}

    checkSession = async () => {
		let user = { attributes: {} }, signedIn = false, privileges = {};
		try {
			user = await Auth.currentAuthenticatedUser({
				bypassCache: true
			})
			signedIn = true;
            localStorage.setItem("jwtToken", user.signInUserSession.idToken.jwtToken);
            localStorage.setItem('userEmail', user.attributes.email);
            localStorage.setItem('userName', user.attributes.name);
            localStorage.setItem('userType', user.attributes['custom:userType']);
            setJwtToken(user.signInUserSession.idToken.jwtToken);
            try {
                privileges = JSON.parse(user.attributes['custom:privileges']);
            } catch(e) { console.log(e) }
            this.refreshToken();

		} catch(err) {
			console.log("cs -- error log -- ", err);
		}
		this.setState({user, signedIn, privileges});
	}

    refreshToken = async () => {
    	let refreshToken = setInterval(async () => {
            try {
                let privileges = {};
                const user = await Auth.currentAuthenticatedUser();
    			const session = await Auth.currentSession();
                user.refreshSession(session.refreshToken, (err, session) => {
                    const { idToken } = session;
                    localStorage.setItem("jwtToken", idToken);
                    localStorage.setItem('userEmail', user.attributes.email);
                    localStorage.setItem('userName', user.attributes.name);
                    setJwtToken(idToken);
                    try {
                        privileges = JSON.parse(user.attributes['custom:privileges']);
                    } catch(e) { console.log(e) }
                    console.log(user, privileges);
                    this.setState({ ...this.state, user, signedIn: true, privileges });
                });
            } catch(err) {
                clearInterval(refreshToken);
                this.setState({ ...this.state, user: { attributes: {} }, signedIn: true, privileges: {} });
    			console.log("cs -- error log -- ", err);
                window.location.href = "/logout";
            }
    	}, 30 * 60 * 1000);
    	this.setState({ ...this.state, refreshToken });
    }

    setAuth = (user = {}, signedIn = true, privileges = {}) => {
        clearInterval(this.state.refreshToken);
		if(signedIn) {
			this.refreshToken();
		}
    	this.setState({...this.state, user, signedIn, privileges});
    }

    render() {
        const { user, signedIn, privileges } = this.state;

        return (
            <AuthContext.Provider value={{user, signedIn, privileges, setAuth: this.setAuth}}>
                <OverlayLoader/>
                <BrowserRouter>
                    <LastLocationProvider>
                        <React.Suspense fallback={loading}>
                            <Switch>
                                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                                {
                                    signedIn === null ? (
										<div>Loading...</div>
									) : (
                                        <>
                                            {
                                                signedIn ? (
                                                    <>
                                                        <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                                                        <Route
                                    						path='/logout'
                                    						render={props => {
                                    							Auth.signOut();
                                    							localStorage.removeItem('userEmail');
                                    							localStorage.removeItem('jwtToken');
                                    							window.localStorage.clear();
                                    							let cookies = Cookies.get();
                                    							Object.keys(cookies).map((key) => {
                                    								Cookies.remove(key);
                                                                    return key;
                                    							});
                                    							this.setAuth({}, false);
                                    							return <Redirect to='/login' />;
                                    						}}
                                    					/>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Route path="/login" name="Login" render={props => <Login {...props}/>} />
                                                        <Route path="/signup" name="Sign Up" render={props => <Signup {...props}/>} />
                                                        <Route path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props}/>} />
                                                        <Route path="/new-password" name="New Password" render={props => <NewPassword {...props}/>} />
                                                        <Redirect from="/" to="/login"/>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </Switch>
                        </React.Suspense>
                    </LastLocationProvider>
                </BrowserRouter>
            </AuthContext.Provider>
        );
    }
}

export default App;
