export const logoNegative = ['608 134', `
  <title>re:Skill</title>
  <?xml version="1.0" encoding="utf-8"?>
  <!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg width="540" height="128" viewBox="0 0 184 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M59.7154 38.6379L48.4447 27.4062L46.5727 29.2782V33.3471H41.7758V12.0276H46.5727V22.4924L57.0504 12.0276L60.4434 15.4206L51.8246 24.0133L63.1083 35.245L59.7154 38.6379Z" fill="white"/>
  <path d="M78.2918 22.6874C78.2918 21.0754 77.7155 19.6974 76.5628 18.5535C75.4189 17.4008 74.0409 16.8245 72.4289 16.8245C70.7996 16.8245 69.413 17.3965 68.269 18.5405C67.1337 19.6758 66.5661 21.0581 66.5661 22.6874C66.5661 24.3253 67.1337 25.712 68.269 26.8473C69.4043 27.9826 70.791 28.5502 72.4289 28.5502C74.0582 28.5502 75.4405 27.9826 76.5758 26.8473C77.7198 25.7033 78.2918 24.3167 78.2918 22.6874ZM83.0887 22.6874C83.0887 25.6426 82.0487 28.1602 79.9687 30.2402C77.8715 32.3114 75.3582 33.3471 72.4289 33.3471C69.465 33.3471 66.9474 32.3114 64.8761 30.2402C62.8049 28.1689 61.7692 25.6513 61.7692 22.6874C61.7692 19.7581 62.8049 17.2448 64.8761 15.1476C66.9561 13.0676 69.4737 12.0276 72.4289 12.0276C75.3409 12.0276 77.8455 13.0763 79.9427 15.1736C82.04 17.2708 83.0887 19.7754 83.0887 22.6874Z" fill="white"/>
  <path d="M98.2719 33.3471L89.2116 20.7894V33.3471H84.4147V12.0276H89.2116L98.2719 24.5723V12.0276H103.082V33.3471H98.2719Z" fill="white"/>
  <path d="M110.531 16.8245V20.2824H119.059V25.0793H110.531V33.3471H105.734V12.0276H121.724V16.8245H110.531Z" fill="white"/>
  <path d="M137.193 33.3471V25.0793H128.133V33.3471H123.336V12.0276H128.133V20.2824H137.193V12.0276H142.003V33.3471H137.193Z" fill="white"/>
  <path d="M162.647 24.0133C162.647 26.6133 161.741 28.8189 159.93 30.6302C158.119 32.4414 155.917 33.3471 153.326 33.3471C150.726 33.3471 148.52 32.4414 146.709 30.6302C144.898 28.8189 143.992 26.6133 143.992 24.0133V12.0276H148.789V24.0133C148.789 25.27 149.231 26.3403 150.115 27.2242C150.999 28.1082 152.069 28.5502 153.326 28.5502C154.583 28.5502 155.649 28.1082 156.524 27.2242C157.408 26.3403 157.85 25.27 157.85 24.0133V12.0276H162.647V24.0133Z" fill="white"/>
  <path d="M178.48 26.8213C178.48 26.3446 178.307 25.9373 177.96 25.5993C177.622 25.2526 177.219 25.0793 176.751 25.0793H169.446V28.5502H176.751C177.228 28.5502 177.635 28.3812 177.973 28.0432C178.311 27.7052 178.48 27.2979 178.48 26.8213ZM177.947 18.5535C177.947 18.1028 177.783 17.6998 177.453 17.3445C177.133 16.9978 176.734 16.8245 176.257 16.8245H169.446V20.2824H176.218C176.695 20.2824 177.102 20.1134 177.44 19.7754C177.778 19.4374 177.947 19.0301 177.947 18.5535ZM183.316 27.4062C183.316 29.0789 182.636 30.5002 181.275 31.6701C179.975 32.7881 178.476 33.3471 176.777 33.3471H164.649V12.0276H176.218C177.891 12.0276 179.386 12.5736 180.703 13.6656C182.099 14.8096 182.796 16.2005 182.796 17.8385C182.796 19.7278 182.016 21.2357 180.456 22.3624C182.363 23.515 183.316 25.1963 183.316 27.4062Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.40128 33.2107C-1.13377 28.6756 -1.13376 21.3228 3.40129 16.7878L20.1892 0L22.5612 2.37214C21.3518 3.58149 21.3518 5.54223 22.5612 6.75157C23.7705 7.96092 25.7313 7.96085 26.9407 6.7515L29.313 9.12381L14.3916 24.0451L5.92835 17.4898L13.4375 24.9989L4.31367 34.123L3.40128 33.2107Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.437 43.2466L3.40129 33.2108C-1.13376 28.6757 -1.13376 21.3229 3.40129 16.7879L4.31367 15.8755L22.5766 34.1385C22.5377 34.157 22.5013 34.1823 22.4692 34.2144L22.0175 34.6661C21.8664 34.8172 21.8664 35.0623 22.0175 35.2135C22.1687 35.3647 22.4138 35.3647 22.565 35.2135L23.0166 34.7619C23.0488 34.7297 23.0741 34.6933 23.0926 34.6544L29.3129 40.8748L26.9405 43.2471L26.9402 43.2468C25.7309 42.0374 23.7701 42.0375 22.5608 43.2468C21.3514 44.4561 21.3514 46.4169 22.5608 47.6262L22.5611 47.6265L20.1891 49.9986L13.9845 43.794L14.4357 43.3428C14.5868 43.1916 14.5868 42.9465 14.4357 42.7954C14.2845 42.6442 14.0394 42.6442 13.8882 42.7954L13.437 43.2466ZM14.7915 41.8921C14.6403 42.0433 14.6403 42.2884 14.7915 42.4395C14.9427 42.5907 15.1877 42.5907 15.3389 42.4395L16.2422 41.5363C16.3933 41.3851 16.3933 41.14 16.2422 40.9889C16.091 40.8377 15.8459 40.8377 15.6947 40.9889L14.7915 41.8921ZM16.598 40.0856C16.4468 40.2368 16.4468 40.4819 16.598 40.633C16.7492 40.7842 16.9943 40.7842 17.1454 40.633L18.0487 39.7298C18.1999 39.5786 18.1999 39.3335 18.0487 39.1823C17.8975 39.0312 17.6524 39.0312 17.5013 39.1823L16.598 40.0856ZM18.4045 38.2791C18.2533 38.4303 18.2533 38.6753 18.4045 38.8265C18.5557 38.9777 18.8008 38.9777 18.9519 38.8265L19.8552 37.9233C20.0064 37.7721 20.0064 37.527 19.8552 37.3758C19.704 37.2247 19.4589 37.2247 19.3078 37.3758L18.4045 38.2791ZM20.211 36.4726C20.0599 36.6237 20.0599 36.8688 20.211 37.02C20.3622 37.1712 20.6073 37.1712 20.7585 37.02L21.6617 36.1167C21.8129 35.9656 21.8129 35.7205 21.6617 35.5693C21.5106 35.4181 21.2655 35.4181 21.1143 35.5693L20.211 36.4726Z" fill="white"/>
  </svg>
  
`]
