const awsmobile = {
	"aws_project_region": process.env.REACT_APP_AWS_PROJECT_REGION ? process.env.REACT_APP_AWS_PROJECT_REGION : "ap-southeast-1",
	"aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID ? process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID : "ap-southeast-1:ab7364b8-74a5-451a-9850-9337913bd7e",
	"aws_cognito_region": process.env.REACT_APP_AWS_COGNITO_REGION ? process.env.REACT_APP_AWS_COGNITO_REGION : "ap-southeast-1",
	"aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID ? process.env.REACT_APP_AWS_USER_POOLS_ID : "ap-southeast-1_SyGvDooV3",
	"aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID ? process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID : "5omc6avo28i22h27f990mki7mr",
	"oauth": {},
	// "aws_user_files_s3_bucket": "reskill-assets",
    // "aws_user_files_s3_bucket_region": "ap-south-1",
};

export default awsmobile;
